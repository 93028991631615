import {ref, reactive} from 'vue';
import { defineStore } from 'pinia'
import { useApiCall } from '@/components/composables/api'; 

const initState = ()=>{
    return {
        id  :"",
        company_name  :"",
        display_company_name:"",
        company_code  :"",
        approval_status  :"",
        permissions:[],
        plan:{}, 
        point:{},
        plan_service_status:null,
        has_merchant:null
   }
}
export const useCompanyStore = defineStore('company', () => {
    const api = useApiCall()
    const selected_company = reactive(initState())
    const companies = ref()
    function setSelectedCompany (value) {
        selected_company.id = value.id
        selected_company.company_name = value.company_name
        selected_company.display_company_name = value.display_company_name
        selected_company.company_code = value.company_code
        selected_company.approval_status = value.approval_status
        selected_company.plan = value.plan
        selected_company.plan_service_status = value.plan?.plan_service_status
        selected_company.point = value.point?value.point:{}
        selected_company.has_merchant = value.has_merchant
        selected_company.permissions = value.permissions === undefined? [] :value.permissions
    }
   
    function getMyCompanies () {
        return new Promise((resolve, reject) => {
            try {
                api.getApi("companies").then((data)=>{
                    if(data.status && data.companies.length > 0){
                        companies.value = [...data.companies]
                        let hasCompany = data.companies.find(x => x.id == selected_company.id)
                        if(hasCompany){
                            setSelectedCompany({...hasCompany})
                        }else{
                            setSelectedCompany({...data.companies[0]})
                        }
                        
                    } 
                    resolve(data)
                }) 
                
            
            } catch (error) {
                alert("getMyCompanies error")

                reject(false)
            }
        })
    }
    function canFunction(func){
        if(selected_company.permissions.indexOf(`function.${selected_company.id}.${func}`)<0){
            return false
        }else{
            return true
        }
    }
    function validatePlan(allowed = null){
        if(allowed === null || allowed === undefined || allowed.length==0){
            return true
        }else if(allowed.indexOf(selected_company.plan_service_status)>=0){
            return true
        }
            
        return false
    }
    function clear(){
        Object.assign(selected_company,initState())
        companies.value = []
    }
    return { selected_company,companies,
        getMyCompanies,setSelectedCompany,canFunction,clear,validatePlan}
},{ persist: true})
