import { ref,toRefs, reactive, watch,computed,nextTick,onMounted } from 'vue';
const __initDialogBox = () => {
    return {
        show: false,
        title:'訊息',
        message:"",
        loading_mode:false,
        icon:"",
        persistent:false,
        btnConfirmShow:true,
        btnConfirmText:"我知道了",
        btnConfirmEvent:null,
        btnCancelShow:false,
        btnCancelText:"取消",
        btnCancelEvent:null
       
    }
}
const layoutState = reactive({
    mainMenuActive: false,
    staticMenuMode: true,

});
const isAsyncDone = ref(true)
const showBlockFullScreen = ref(false)

const dialogBox = reactive(__initDialogBox());

export function useLayout() {
    const onMenuToggle = () => {
        if(layoutState.staticMenuMode){
            layoutState.mainMenuActive = false
        }else{
            layoutState.mainMenuActive = !layoutState.mainMenuActive;
        }

    };
    const setStaticMode = (value) => {
        layoutState.staticMenuMode = value
    };
    const asyncDone = (value) => {
        isAsyncDone.value = value
    };
    const setBlockFullScreen = (value) => {
        if(value == false){
            setTimeout(()=>{        
                showBlockFullScreen.value = value
            },900)

        }else{
            showBlockFullScreen.value = value
        }
       
       
    };
  
    const openDialogBox = (options) => {
        Object.assign(dialogBox,__initDialogBox())
        Object.assign(dialogBox,options)
        dialogBox.show = true

    }
    const closeDialogBox = (options) => {
        dialogBox.show = false
    }
    const windowSize = ref(window.innerWidth)
    const onResize = ()=>{
        windowSize.value = window.innerWidth
    }
    onMounted (() => addEventListener ('resize', onResize))
    const isDesktop = computed(() => {
        return windowSize.value > 1100
    })
    const isLargeDesktop = computed(() => {
        return windowSize.value > 1440
    })
    watch(isLargeDesktop,(newVal,oldVal)=>{
        if(newVal){
            setStaticMode(true)
        }else{
            setStaticMode(false)

        }
    }, { immediate: true })
    return { 
        isDesktop,isLargeDesktop,
        layoutState: toRefs(layoutState),
        dialogBox,isAsyncDone,showBlockFullScreen,

        openDialogBox,closeDialogBox,setStaticMode,
        onMenuToggle,asyncDone,setBlockFullScreen
    };
}
